



























































































import { Vue } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';

export default Vue.extend({
  data: () => ({
    dialog: false,
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      { text: 'Firma', value: 'company' },
      { text: 'Benutzername', value: 'username' },
      { text: 'E-Mail', value: 'email' },
      { text: 'Strasse', value: 'street' },
      { text: 'PLZ Stadt', value: 'plz_city' },
      { text: 'Telefon', value: 'phone' },
      { text: 'Kundennr.', value: 'customer_id' },
      { text: 'Notizen', value: 'notes' },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    customers: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      company: '',
      username: '',
      email: '',
      street: '',
      plz_city: '',
      phone: '',
      customer_id: '',
      notes: '',
      password: ''
    },
    defaultItem: {
      name: '',
      company: '',
      username: '',
      email: '',
      street: '',
      plz_city: '',
      phone: '',
      customer_id: '',
      notes: '',
      password: ''
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Neuer Benutzer' : 'Benutzer bearbeiten';
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.retrieveCustomers();
  },

  methods: {
    retrieveCustomers() {
      axios
        .get(`${BACKEND_URL}/customers`)
        .then(response => {
          if (response.status === 200) {
            this.customers = response.data.customers;
          }
        })
        .catch(error => {});
    },

    editItem(item: any) {
      this.editedIndex = (this.customers as any).indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.password = '';
      this.dialog = true;
    },

    deleteItem(item: any) {
      if (confirm('Soll dieser Benutzer wirklich gelöscht werden?')) {
        axios
          .post(`${BACKEND_URL}/customers/delete`, item)
          .then(() => this.retrieveCustomers());
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      // edited
      if (this.editedIndex > -1) {
        axios
          .post(`${BACKEND_URL}/customers/update`, this.editedItem)
          .then(() => this.retrieveCustomers());
      }
      // new user
      else {
        axios
          .post(`${BACKEND_URL}/customers/create`, this.editedItem)
          .then(() => this.retrieveCustomers());
      }
      this.close();
      this.retrieveCustomers();
    }
  }
});
