





































import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { BACKEND_URL } from '@/za_conf';

@Component
export default class Login extends Vue {
  password = null;
  username = null;
  error: any = '';

  login() {
    this.error = null;
    axios
      .post(`${BACKEND_URL}/login`, {
        username: this.username,
        password: this.password
      })
      .then(response => {
        if (response.status === 200) {
          const user = jwtDecode(this.$cookies.get('jwt_header_payload'));
          this.$store.commit('setUser', user);
          let path: any = this.$route.query.redirect
            ? this.$route.query.redirect
            : '/';
          this.$router.push(path);
        }
      })
      .catch(error => {
        this.error =
          'Login fehlgeschlagen! Bitte Username und Passwort überprüfen.';
      });
  }
}
