

































import { Component, Prop, Vue } from 'vue-property-decorator';

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { BACKEND_URL } from '@/za_conf';

import Login from '@/components/login/Login.vue';
import Register from '@/components/login/Register.vue';

@Component({
  components: { Register, Login }
})
export default class LogReg extends Vue {
  error = '';
  tab = 1;

  change_to_request() {
    this.tab = 0;
  }
}
