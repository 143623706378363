<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-select :items="dynamic_tables" item-text="name" v-model="selectedTable" return-object> </v-select>
      </v-col>
      <v-col cols="12">
        <v-data-table :items="getItems" sort-by="name">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ selectedTable.titleText }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="setItemsFromOriginals">Reset</v-btn>
              <v-btn @click="save" color="green" :disabled="!anyChanged">Save</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:header>
            <tr style="text-align: center">
              <th>{{ selectedTable.headerText }}</th>
              <th v-for="(header, ind) in headers" :key="`${header}_${ind}`">
                {{ header }}
              </th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr style="text-align: left">
              <th>{{ item.header }}</th>
              <td
                v-for="(header, ind) in headers"
                :key="`${item.header}_${header}_${ind}`"
                :style="getStyle(item, header)"
              >
                <v-text-field
                  type="number"
                  :value="item[header]"
                  :suffix="selectedTable.valueSuffix"
                  @blur="updateItem(item, header, $event.target.value)"
                  :disabled="!item[header]"
                ></v-text-field>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            Keine Daten
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'ParamsDynamic',
  data: () => ({
    items_originals: [],
    items: [],
    headers: [],
    dynamic_tables: [
      {
        config: {
          table: 'ZARuchCuttingSpeed',
          x: 'material',
          y: 'thickness',
          edit: 'cutting_time_per_meter'
        },
        headerText: 'Dicke \\ Material',
        titleText: 'Parametrisierung Schnittgeschwindigkeit',
        name: 'Schnittgeschwindigkeit',
        valueSuffix: '[sec/m]'
      },
      {
        config: {
          table: 'ZARuchCuttingGasConsumption',
          x: 'gas_type',
          y: 'material',
          edit: 'consumption_per_hour'
        },
        headerText: 'Material \\ Gastyp',
        titleText: 'Parametrisierung Schneidgasverbrauch',
        name: 'Schneidgasverbrauch',
        valueSuffix: '[m³/h]'
      },
      {
        config: {
          table: 'ZARuchGasCost',
          x: 'gas_type',
          y: 'material',
          edit: 'cost_per_cubic_meter'
        },
        headerText: 'Material \\ Gastyp',
        titleText: 'Parametrisierung Schneidgasverbrauch',
        name: 'Schneidgasverbrauch',
        valueSuffix: '[m³/h]'
      }
    ],
    selectedTable: null
  }),

  computed: {
    getItems() {
      return this.items;
    },
    anyChanged() {
      return this.items.some(function(val) {
        for (let i of Object.keys(val)) {
          if (i.endsWith('_changed')) {
            if (val[i]) {
              return true;
            }
          }
        }
        return false;
      });
    }
  },
  watch: {
    selectedTable(oldVal, newVal) {
      this.receiveOriginals();
    }
  },
  methods: {
    getStyle(item, header) {
      if (item[`${header}_changed`]) {
        return 'background: green';
      }
      return '';
    },
    updateItem(item, header, value) {
      if (item[header] !== Number(value)) {
        item[`${header}_changed`] = true;
        item[header] = Number(value);
      }
    },
    receiveOriginals() {
      axios
        .post(`${BACKEND_URL}/table`, {
          ...this.selectedTable.config
        })
        .then(response => {
          if (response.status === 200) {
            this.items_originals = response.data.items;
            this.headers = response.data.headers;
            this.setItemsFromOriginals();
          }
        })
        .catch(error => {});
    },
    setItemsFromOriginals() {
      this.items = [];
      for (let e of this.items_originals) {
        let e_ext = Object.assign({}, e);
        for (let i of Object.keys(e)) {
          e_ext[`${i}_changed`] = false;
        }
        this.items.push(e_ext);
      }
    },
    save() {
      let updateVals = [];

      for (let i = 0; i < this.items.length; i++) {
        for (let k of Object.keys(this.items_originals[i])) {
          let item = this.items[i];
          if (item[`${k}_changed`]) {
            updateVals.push({ y: item.header, x: k, edit: item[k] });
          }
        }
      }

      // TODO SEND TO SERVER AND REFRESH VALUES
      axios
        .post(`${BACKEND_URL}/table_update`, {
          ...this.selectedTable.config,
          edits: updateVals
        })
        .then(response => {
          if (response.status === 200) {
            this.receiveOriginals();
          }
        })
        .catch(error => {});
    }
  },
  created() {
    this.selectedTable = this.dynamic_tables[0];
  }
};
</script>

<style scoped></style>
