<template>
  <div v-if="message_sent">
    Ihre Registrierungsanfrage wurde erfolgreich übermittelt. Nachdem die
    Anfrage durch das Unternehmen bearbeitet wurde, werden Sie die benötigten
    Zugangsdaten für die Aktivierung erhalten. Bitte haben Sie dafür 1-2
    Arbeitstage Geduld – Vielen Dank!
  </div>
  <v-form v-else ref="form" v-model="valid">
    <div>
      Hier können Sie eine Registierung für ein Kundenkonto auslösen. Nach einer
      Prüfung werden Sie von uns die benötigten Angaben für die Aktivierung des
      Accounts erhalten.
    </div>
    <v-text-field
      id="email"
      name="email"
      label="Email"
      type="text"
      v-model="email"
      :rules="get_rules_for('Email')"
      required
      @keyup.enter="requestAccount"
    ></v-text-field>
    <v-text-field
      id="company"
      name="company"
      label="Firma"
      type="text"
      v-model="company"
      :rules="get_rules_for('Firma')"
      required
      @keyup.enter="requestAccount"
    ></v-text-field>
    <v-text-field
      id="forename"
      name="forename"
      label="Vorname"
      type="text"
      v-model="forename"
      :rules="get_rules_for('Vorname')"
      required
      @keyup.enter="requestAccount"
    ></v-text-field>
    <v-text-field
      id="surname"
      name="surname"
      label="Nachname"
      type="text"
      :rules="get_rules_for('Nachname')"
      v-model="surname"
      required
      @keyup.enter="requestAccount"
    ></v-text-field>
    <v-text-field
      id="street"
      name="street"
      label="Strasse"
      type="text"
      v-model="street"
      :rules="get_rules_for('Strasse')"
      required
      @keyup.enter="requestAccount"
    ></v-text-field>
    <v-text-field
      id="plz_city"
      name="plz_city"
      label="PLZ & Stadt"
      type="text"
      v-model="plz_city"
      :rules="get_rules_for('PLZ & Stadt')"
      required
      @keyup.enter="requestAccount"
    ></v-text-field>
    <v-text-field
      id="telephone"
      name="telephone"
      label="Telefon-Nummer"
      type="text"
      v-model="telephone"
      :rules="get_rules_for('Telefon-Nummer')"
      required
      @keyup.enter="requestAccount"
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn @click="requestAccount" color="primary">Anfrage auslösen</v-btn>
  </v-form>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'Register',
  data() {
    return {
      valid: false,
      email: null,
      company: null,
      forename: null,
      surname: null,
      street: null,
      plz_city: null,
      telephone: null,
      message_sent: false
    };
  },
  methods: {
    get_rules_for(name) {
      return [v => !!v || `${name} benötigt`];
    },

    requestAccount() {
      let valid = this.$refs.form.validate();

      if (valid) {
        axios
          .post(`${BACKEND_URL}/request_account`, {
            email: this.email,
            company: this.company,
            forename: this.forename,
            surname: this.surname,
            street: this.street,
            plz_city: this.plz_city,
            telephone: this.telephone
          })
          .then(value => {
            this.message_sent = true;
            this.$refs.form.reset();
            this.$router.push('/');
          })
          .catch(e => {});
      } else {
      }
    }
  }
};
</script>

<style scoped></style>
